/**
 * @file
 * Collaborations modules
 *
 */

// collaborations list
.view-collaborations {
  > header {
    h1 {
      font-size: 180%;
      margin-bottom: 20px;
    }
  }

  .item-list {
    > ul {
      @include simple-column-container;
    }
    .views-row {
      @include simple-column(3, 8%);
      position: relative;
      height: 23vw;
      max-height: 320px;
      overflow: hidden;
      @media screen and (max-width: $big_screen_width) {
        height: 35vw;
        max-height: none;
      }
      @media screen and (max-width: $small_screen_width) {
        @include simple-column(2, 8%);
        height: 54vw;
        max-height: none;
      }
      @media screen and (max-width: $micro_screen_width) {
        @include simple-column(1, 0);
        height: auto;
      }
      .views-field-field-images {
        margin-bottom: 7px;
        div, a, img {
          display: block;
          width: 100%;
        }
      }

      .views-field-title {
        font-size: 140%;
        font-weight: 600;
        text-align: center;
        a {
          color: black;
        }
      }

      .views-field-edit-node {
        position: absolute;
        bottom: 10px;
        right: 0;
      }
    }
  }
}

// intro block
#block-block-5 {
  .content {
    p {
      //@extend %narrowbody;
    }
  }
}

// collaborations page exposed filters
#block-views--exp-collaborations-page_1 {
  border-top: 1px solid $theme_red;
  margin-top: 15px;
  padding-top: 15px;
  > .inner {
    > header {
      h1 {
        color: $theme_red;
      }
    }
  }
}
