/**
 * @file
 * Generic and Drupal styles
 */

/**
 * Accessibility features.
 */

/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
%element-invisible {
  @include element-invisible;
}

/* Turns off the element-invisible effect. */
%element-invisible-off {
  @include element-invisible-off;
}

.element-focusable,
%element-focusable {
  @extend %element-invisible;

  &:active,
  &:focus {
    @extend %element-invisible-off;
  }
}

#skip-link {
  @extend %element-invisible;
}

// maintenance page
body.maintenance-page {
  #page {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 400px;
    margin-left: -300px;
    margin-top: -200px;
    @media screen and (max-width: $small_screen_width) {
      @include one-columnize;
    }
  }
  #header {
    padding-top: 30px;
    .logo {
      display: block;
      max-width: 320px;
      margin: auto;
    }
  }
  #main {
  }
  #content {
    padding-top: 30px;
    font-size: 160%;
    line-height: 1.4;
    p {
      font-size: 100%;
    }
  }
}

/* social links */
.social-network-list {
  overflow: hidden;
  li {
    float: left;
    margin-left: 12px;
    &:first-child {
      margin-left: 0;
    }
    a {
      display: block;
      margin-right: -3px;
      &:hover {
        opacity: .6;
      }
      &:before {
        @include iconize(0, 0, 1, 1);
        content: " ";
      }
      &[data-social-network="facebook"]:before {
        background-position: 0 0;
      }
      &[data-social-network="twitter"]:before {
        background-position: -25px 0;
      }
      &[data-social-network="instagram"]:before {
        background-position: -50px 0;
      }
      span {
        display: none;
      }
    }
  }
}

// primary and secondary tabs
.tabs-primary, .tabs-secondary {
  @extend %clearfix;
  margin: 20px 0;
  padding-left: 2px;
  border-bottom: 1px solid $theme_grey_lighter;
  li {
    float: left;
    margin: 0 2px;
    position: relative;
    top: 1px;
    a {
      display: block;
      font-size: 160%;
      padding: 10px 15px;
      background-color: $theme_grey_lightest;
      border: 1px solid $theme_grey_lighter;
      &:hover {
        background-color: white;
      }
      &.active {
        background-color: white;
        border-bottom-color: white;
        &:hover {

        }
      }
    }
  }
}

/**
 * Pager.
 */

/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  margin: 30px 0;
  padding: 0;
  text-align: center;

  // infinite scroll pager
  &.pager--infinite-scroll {
    .pager__item {
      display: inline-block;
      font-size: 180%;
      font-weight: bold;
      text-align: center;
      list-style-type: none;
      background-image: none;
      a {
        display: block;
        padding: 5px;
      }
    }
  }

  // normal pager
  .pager-item {
    @extend %pager__item;
  }
  .pager-current {
    @extend %pager__item;
    color: $theme_red;
  }
  .pager-first,
  .pager-previous,
  .pager-next,
  .pager-last,
  .pager-ellipsis {
    @extend %pager__item;
  }
}

/**
 * Messages
 */
.messages {
  margin-bottom: 20px;
  padding: 15px 20px;
  color: white;
  font-size: 140%;
  background-color: $theme_grey_light;
  &.warning {
    background-color: $theme_warning;
  }
  &.error {
    background-color: $theme_error;
  }

  body.front & {
    margin-bottom: 5px;
    border: 1px solid $theme_grey_light;
    &.status {
      background-color: white;
      color: black;
    }
    @media screen and (max-width: $small_screen_width) {
      width: 90%;
      margin-left: -45%;
    }
  }
}

// edit buttons
%edit-button {
  font-size: 12px;
  padding: .1em .3em;
  border: 1px solid $theme_red;
  &:hover {
    opacity: .6;
  }
}
.edit-btn {
  @extend %edit-button;
}
.views-field-edit-node {
  margin-top: 3px;
  a {
    @extend %edit-button;
  }
}

/**
 * Views filters
 */
.views-exposed-form {
  .views-exposed-widgets {
    .views-exposed-widget {
      float: none;
      padding: 0;
      margin-bottom: 15px;
      .form-item {
        label {
          margin-right: 0;
        }
      }
      label {
        font-size: 140%;
        font-weight: normal;
        text-transform: uppercase;
        color: $theme_grey_darker;
      }
      input[type="text"], select {
        font-size: 140%;
        padding: 5px;
      }
      select {
        max-width: 320px;
      }
      .views-widget {
        label {
          &:hover {
            text-decoration: underline;
          }
        }
        input[type="radio"]:checked + label {
          color: $theme_grey_darker;
          text-decoration: underline;
        }
      }
      .bef-tree {
        .bef-tree-child {
          padding-left: 20px;
        }
      }
      .bef-select-as-links {
        .form-type-bef-link {
          font-size: 140%;
          line-height: 1.2;
          margin-bottom: .8em;
          a {
            color: black;
            &:hover, &.active {
              color: $theme_red;
            }
          }
        }
      }
    }
  }
}

.view-empty {
  font-size: 160%;
  p {
    font-size: 100%;
  }
}

/**
 * Forms
 */

.form-item {
  position: relative;
  margin-bottom: 20px;

  .form-item {
    margin-bottom: 10px;
  }

  .description {
    font-size: 130%;
    line-height: 1.25;
    padding: .3em 0;
  }
}

.password-strength {
  margin-top: 0;
  width: 240px;
  .password-strength-text {
    display: block;
    font-size: 120%;
  }
  .password-strength-title {
    font-size: 120%;
  }
  .password-indicator {
    height: 6px;
  }
}

div.password-confirm {
  margin-top: 5px;
  width: 180px;
  font-size: 120%;
  text-align: right;
}

div.form-item div.password-suggestions {
  display: none !important;
}

fieldset {
  border: none;
  padding: 0;
  margin-bottom: 30px;
}

legend {
  font-size: 220%;
  font-weight: bold;
  margin-bottom: .4em;
}

label {
  margin-bottom: .4em;
  font-size: 170%;
  font-weight: normal;
}

/* buttons */
a[data-action], input[type="submit"], input[type="button"], button {
  @include buttonize;
}

input[type="submit"][disabled], input[type="button"][disabled] {
  opacity: .2;
}

input[type="text"], input[type="password"], input[type="email"], textarea, select {
  display: block;
  font-size: 180%;
  width: 100%;
  padding: 10px;
  border: 1px solid $theme_grey_lighter;
  &.error {
    border-color: $theme_error;
  }
}

input[type="text"], input[type="email"] {
  &[size] {
    width: auto;
  }
}

select {
  background-color: $body_background;
  border-radius: 0;
  height: 43px;
}

/* radios */
input[type="radio"]:not(:checked),
input[type="radio"]:checked {
  position: absolute;
  left: -9999px;
}

input[type="radio"]:not(:checked) + label,
input[type="radio"]:checked + label {
  position: relative;
  display: block;
  padding-left: 30px;
  cursor: pointer;
}

/* shape */
input[type="radio"]:not(:checked) + label:before,
input[type="radio"]:checked + label:before {
  display: block;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  transition: all .2s;
  box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
}

/* checked shape */
input[type="radio"]:not(:checked) + label:after,
input[type="radio"]:checked + label:after {
  display: block;
  background-color: $theme_grey_light;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 7px;
  left: 7px;
  transition: all .2s;
}

/* disabled radio */
input[type="radio"]:disabled {
}

input[type="radio"]:disabled + label {
  color: #aaa;
}

input[type="radio"]:disabled + label:before {
  opacity: .4;
}

input[type="radio"]:disabled + label:after {
  opacity: .4;
}

/* checked mark changes */
input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="radio"]:not(:checked) + label:hover:after,
input[type="radio"]:checked + label:after {
  opacity: 1;
}

input[type="radio"]:checked + label:after {
}

/* checkboxes */
input[type="checkbox"]:not(:checked),
input[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

input[type="checkbox"]:not(:checked) + label,
input[type="checkbox"]:checked + label {
  position: relative;
  display: block;
  padding-left: 30px;
  cursor: pointer;
}

/* shape */
input[type="checkbox"]:not(:checked) + label:before,
input[type="checkbox"]:checked + label:before {
  display: block;
  width: 16px;
  height: 16px;
  background: white;
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  transition: all .2s;
  box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
}

/* checked shape */
input[type="checkbox"]:not(:checked) + label:after,
input[type="checkbox"]:checked + label:after {
  display: block;
  background-color: $theme_grey_light;
  width: 8px;
  height: 8px;
  content: '';
  position: absolute;
  top: 7px;
  left: 7px;
  transition: all .2s;
}

/* disabled checkbox */
input[type="checkbox"]:disabled {
}

input[type="checkbox"]:disabled + label {
  color: #aaa;
}

input[type="checkbox"]:disabled + label:before {
  opacity: .4;
}

input[type="checkbox"]:disabled + label:after {
  opacity: .4;
}

/* checked mark changes */
input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="checkbox"]:not(:checked) + label:hover:after,
input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

input[type="checkbox"]:checked + label:after {
}

/**
 * Tables
 */
table {
  border-top: 1px solid $theme_grey_lighter;
  border-left: 1px solid $theme_grey_lighter;
  th, td {
    font-size: 160%;
    padding: 5px 10px;
    border-bottom: 1px solid $theme_grey_lighter;
    border-right: 1px solid $theme_grey_lighter;
    table {
      th, td {
        font-size: 100%;
      }
    }
  }
}

// user profile
.profile {
  .field {
    font-size: $base-font-size;
    line-height: $base-line-height;
    margin-bottom: 1em;
  }
}