/**
 * @file
 * Page modules
 *
 */

#page {
  position: relative;
  z-index: 1;
  overflow: hidden;

  &.with-image {
    height: 100vh;
    overflow: scroll;
    .page-image-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      @media screen and (max-width: $big_screen_width) {
        top: 50px;
        width: 100%;
        height: 320px;
      }
      @media screen and (max-width: $small_screen_width) {
        height: 240px;
      }
    }
  }
}

#header {}

#content {
  @extend %wrapper;
  @extend %clearfix;
  padding-top: 50px;
  body.not-front & {
    @media screen and (min-width: $bigger_screen_width) {
      min-height: 600px;
    }
  }
  body.page-obra & {
    @media screen and (min-width: $bigger_screen_width) {
      min-height: 730px;
    }
  }
  @media screen and (max-width: $mobile_menu_min_width) {
    padding-top: 90px;
  }
}

/* center content */
#content-inner {
  body.one-sidebar & {
    padding-left: 390px;
    @media screen and (max-width: $big_screen_width) {
      padding-left: 0;
    }
  }
  body.one-sidebar.front & {
    padding-left: 0;
  }
  #page.with-image & {
    float: right;
    width: 50%;
    padding-left: 65px;
    @media screen and (max-width: $big_screen_width) {
      float: none;
      width: auto;
      padding-left: 0;
      padding-top: 320px;
    }
    @media screen and (max-width: $small_screen_width) {
      padding-top: 240px;
    }
  }
}

/* first sidebar (header) */
#sidebar-first {
  body.not-front & {
    position: absolute;
    z-index: 2;
    width: 320px;
    margin-right: 70px;
    @media screen and (max-width: $big_screen_width) {
      @include one-columnize;
    }
    @media screen and (max-width: $mobile_menu_min_width) {
      @include mobileheader;
    }
  }

  // site logo
  .logo {
    display: block;
    margin-top: -22px;
    margin-bottom: 10px;
    padding: 11px 18px 13px 28px;
    max-width: 400px;
    background-color: white;
    @media screen and (max-width: $mobile_menu_min_width) {
      margin: 0 auto;
      max-width: 250px;
      padding: 5px;
    }
    @media screen and (max-width: $nano_screen_width) {
      margin: 0;
    }
  }
}

.region-sidebar-first {
  // sidebar blocks
  .block {
    padding: 10px 22px;
    background-color: white;
    > .inner {
      > header {
        h1 {
          font-size: 160%;
          text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
    }
  }

  // main menu: second level
  .block-menu-block {
    margin-top: 10px;
    padding-left: 52px;
    @media screen and (max-width: $mobile_menu_min_width) {
      display: none;
    }
    ul.menu {
      li {
        font-size: 180%;
        line-height: 1.25;
        margin-bottom: .5em;
        a {
          color: black;
          &.active, &.active-trail {
            color: $theme_red;
          }
        }
        ul {
          padding-left: 30px;
        }
      }
    }
  }
}

/* language selector */
#block-locale-language {
  padding-bottom: 0;
  @media screen and (max-width: $mobile_menu_min_width) {
    display: none;
  }
  > .inner {
    padding-bottom: 10px;
    border-bottom: 1px solid black;
  }
  ul {
    li {
      a {
        font-size: 140%;
        color: black;
        &.active {
          display: none;
        }
      }
    }
  }
}

/* main menu */
#block-system-main-menu {
  padding-top: 0;
  padding-bottom: 0;
  @media screen and (max-width: $mobile_menu_min_width) {
    display: none;
  }
  nav {
    > ul.menu {
      margin: 0;
      padding: 0;
      > li {
        list-style-type: none;
        list-style-image: none;
        a {
          display: block;
          color: black;
          font-size: 200%;
          padding: 7px 0;
          &:hover {
            color: $theme_red;
          }
          @media screen and (max-width: $bigger_screen_width) {
            font-size: 160%;
          }
        }
        &.active-trail > a, a.active {
          color: $theme_red;
          font-weight: 600;
        }
        ul.menu {
          display: none;
        }
      }
    }
  }
}

/* responsive menu */
.mobile-menu {
  z-index: 2;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  transition: all .5s ease;
  @media screen and (min-width: $mobile_menu_min_width) {
    display: none;
  }
  &.opened {
    right: 0;
    > .inner {
      opacity: 1;
    }
  }
  [data-action="open-mobile-menu"] {
    @include hide-text;
    position: fixed;
    top: 0;
    width: 50px;
    height: 50px;
    right: 5px;
    outline: none;
    z-index: 2;
    background: none;
    border: none;
    transition: all .5s ease;
    &:before {
      @include iconize(-350px, 0, 2, 2);
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &.opened [data-action="open-mobile-menu"] {
    &:before {
      background-position: -350px -50px;
    }
  }
  > .inner {
    opacity: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    margin: 0;
    z-index: 1;
    background: white;
    > nav {
      padding: 10px 0;
      li {
        list-style-type: none;
        list-style-image: none;
        a {
          display: block;
          font-size: 20px;
          font-weight: 200;
          padding: 10px 20px;
          color: black;
          &.active, &:active {
            color: $theme_red;
          }
        }
        ul.menu {
          padding-left: 30px;
        }
      }
    }
    ul.language-switcher-locale-url {
      padding: 10px 20px;
      li {
        a {
          font-size: 140%;
          color: black;
          &.active {
            display: none;
          }
        }
      }
    }
    .social-network-list {
      padding: 10px 20px;
    }
  }
}

/* social links block */
#block-evaarmisen-social_networks_links {
  @media screen and (max-width: $mobile_menu_min_width) {
    display: none;
  }
}

// user login block
#block-user-login {
  .item-list {
    margin-bottom: 10px;
    li {
      font-size: 140%;
      display: inline;
      margin-right: 10px;
    }
  }
}

.tabs-primary {
  @extend %wrapper;
  margin-bottom: 20px;
}

/* page or article title */
h1.page-title {
  font-size: 300%;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 1em;
  @media screen and (max-width: $mediumbig_screen_width) {
    font-size: 240%;
  }
  body.page-tienda & {
    visibility: hidden;
  }
  body.page-tienda-productos & {
    visibility: visible;
  }

  // is wholesaler
  .wholesaler {
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    margin-left: 6px;
    vertical-align: middle;
    text-transform: uppercase;
    color: $theme_red;
    border: 1px solid $theme_red;
    padding: 3px;
  }

  // Obra filters title
  &.pieces-filter-title {
    font-size: 180%;
    color: $theme_grey_light;
    .filter {
      &:before {
        content: '>';
        margin: 0 3px;
      }
      &:first-child:before {
        content: '';
      }
      strong {
        color: black;
        &:before {
          content: ':';
          margin-right: 3px;
          color: $theme_grey_light;
        }
      }
      .city {
        font-style: normal;
        font-weight: normal;
        &:before {
          content: '-';
          margin: 0 3px 0 0;
        }
      }
      .country {
        font-style: normal;
        font-weight: normal;
        &:before {
          content: '(';
          margin: 0 0 0 3px;
        }
        &:after {
          content: ')';
          margin: 0 3px 0 0;
        }
      }
    }
  }
}
.field-name-field-title-drawn {
  margin-bottom: 25px;
  @media screen and (max-width: $mobile_menu_min_width) {
    margin-bottom: 0;
  }
  img {
    max-height: 63px;
  }
}

// content blocks
.block-block {
  margin-bottom: 30px;
  > .inner {
    > .content {
      ul {
        padding-left: 23px;
        li {
          font-size: $base-font-size;
          line-height: $base-line-height;
          margin: 0 0 .5em;
          list-style: disc;
          @media screen and (max-width: $medium_screen_width) {
            font-size: $base-font-size-smaller;
          }
        }
      }
      ol {
        li {
          font-size: $base-font-size;
          line-height: $base-line-height;
          margin: 0 0 .5em;
          @media screen and (max-width: $medium_screen_width) {
            font-size: $base-font-size-smaller;
          }
        }
      }
    }
  }
}

/* detail */
article.node-full {
  .field-type-text-with-summary, .field-type-text-long {
    @extend %narrowbody;
    margin-bottom: 30px;
    p {
      a.btn {
        display: block;
        margin: 30px auto;
        width: 250px;
        transition: all .3s ease;
        @media screen and (max-width: $big_screen_width) {
          width: 100%;
        }
        body.front & {
          width: 350px;
          margin: 10px auto 60px;
          font-weight: bold;
          font-size: 28px;
          color: $theme_red;
          padding: 10px;
          text-align: center;
          border: 2px solid $theme_red;
          &:hover {
            background-color: $theme_red;
            color: white;
          }
          @media screen and (max-width: $big_screen_width) {
            width: 100%;
          }
        }
      }
    }
    h2 {
      font-size: 220%;
      line-height: 1.1;
      margin-bottom: .6em;
      padding-top: .5em;
      &:first-child {
        padding-top: 0;
      }
      @media screen and (max-width: $mediumbig_screen_width) {
        font-size: 180%;
        line-height: 1.2;
      }
    }
    h3 {
      font-size: 180%;
      line-height: 1.2;
      margin-bottom: .6em;
    }
    ul {
      padding-left: 23px;
      li {
        font-size: $base-font-size;
        line-height: $base-line-height;
        margin: 0 0 .5em;
        list-style: disc;
        @media screen and (max-width: $medium_screen_width) {
          font-size: $base-font-size-smaller;
        }
      }
    }
    ol {
      li {
        font-size: $base-font-size;
        line-height: $base-line-height;
        margin: 0 0 .5em;
        @media screen and (max-width: $medium_screen_width) {
          font-size: $base-font-size-smaller;
        }
      }
    }
    table {
      margin-bottom: 20px;
      th, td {
        font-size: 120%;
      }
    }
    iframe {
      margin: 30px 0;
    }
  }

  // image gallery
  .image-gallery {
    margin-top: 30px;
    margin-bottom: 30px;
    .full-image {
      margin: 0 0 30px;
      background: white;
      figure {
        height: 100%;
      }
      img {
        display: block;
        margin: 0 auto;
      }
    }
    .thumb-list {
      text-align: center;
      .thumb {
        position: relative;
        display: inline-block;
        height: 86px;
        margin: 0 7px 21px 0;
        cursor: pointer;
        &.active {
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -14px;
            left: 0;
            width: 100%;
            height: 7px;
            background-color: $theme_red;
          }
        }
        img {
          height: 100%;
          display: block;
        }
      }
    }
  }

  // node pager
  .image-container {
    position: relative;
  }
  .node-pager {
    a[data-action] {
      position: absolute;
      top: 50%;
      margin-top: -30px;
      padding: 5px;
      border: none;
      background-color: rgba($theme_grey_light, .3);
      span {
        display: none;
      }
    }
    [data-action="page-previous"] {
      left: 0;
      &:before {
        @include iconize(-200px, -50px, 1, 2);
        content: " ";
      }
    }
    [data-action="page-next"] {
      right: 0;
      &:after {
        @include iconize(-225px, -50px, 1, 2);
        content: " ";
      }
    }
  }

  .field-name-field-file {
    font-size: $base-font-size;
    .file-icon {
      display: none;
    }
  }

  /* Page detail */
  &.node-page {

  }

  /* Banner/Slide detail */
  &.node-banner {
    position: relative;
    .content {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 250px;
      height: 160px;
      padding: 15px 20px;
      background-color: white;
      body.front & {
        bottom: auto;
        top: 40vh;
        right: 20vw;
      }
      @media screen and (max-width: $small_screen_width) {
        @include one-columnize;
        body.front & {
          top: auto;
          right: auto;
        }
      }
      h2 {
        display: none;
      }
      .field-type-text-with-summary {
        @media screen and (max-width: $small_screen_width) {
          padding: 0 $page_horizontal_padding;
        }
        p {
          font-size: 140%;
          line-height: 1.33;
        }
      }
      .field-name-field-link {
        position: absolute;
        bottom: 15px;
        left: 20px;
        @media screen and (max-width: $small_screen_width) {
          left: $page_horizontal_padding;
        }
        a {
          font-size: 160%;
          font-weight: 600;
          &:after {
            content: ">";
            margin-left: 5px;
          }
        }
      }
    }
  }



  /* Piece detail */
  &.node-piece {
    position: relative;
    [data-action="back-to-list"] {
      @extend %go-back-link;
    }

    .field-name-field-image {
      img {
        display: block;
        margin: 0 auto 30px;
      }
    }

    .info {
      &:after {
        display: block;
        width: 240px;
        content: "";
        margin: 20px auto;
        border-top: 1px solid $theme_red;
      }

      h1.page-title {
        text-align: center;
        font-size: 180%;
        line-height: 1.2;
        margin-bottom: .2em;
        margin-right: .4em;
      }

      .field {
        text-align: center;
        font-size: 140%;
        margin-bottom: .4em;

        &.field-name-field-title-en {
          font-style: italic;
          font-weight: bold;
          color: $theme_grey_darker;
        }

        &.field-available-status {
          font-weight: 600;
          &[data-status="0"] {
            color: $theme_grey_lighter;
          }
        }
      }
    }
  }

  /* Collaboration detail */
  &.node-collaboration {
    position: relative;
    [data-action="back-to-list"] {
      @extend %go-back-link;
    }

    .info {
      @extend %narrowbody;
      margin: 0 auto;
      &:after {
        display: block;
        width: 240px;
        content: "";
        margin: 20px auto;
        border-top: 1px solid $theme_red;
      }

      h1.page-title {
        text-align: center;
        font-size: 180%;
        line-height: 1.2;
        margin-bottom: 1em;
        margin-right: .4em;
      }

      .field {
        text-align: center;
        font-size: 140%;
        margin-bottom: .4em;

        &.field-label-inline {
          div {
            display: inline-block;
          }
          .field-label {
            float: none;
          }
          .field-items {
            float: none;
          }
        }

        &.field-name-body {
          text-align: left;
          font-size: 100%;
          margin-bottom: 0;
        }

        &.field-venue {
          span {

          }
          span + span {
            &:before {
              content: ", ";
              display: inline;
            }
          }
        }
      }
    }
  }

  /* News detail */
  &.node-news {
    position: relative;
    [data-action="back-to-list"] {
      @extend %go-back-link;
    }

    .info {
      @extend %narrowbody;
      margin: 0 auto;
      &:after {
        display: block;
        width: 240px;
        content: "";
        margin: 20px auto;
        border-top: 1px solid $theme_red;
      }

      h1.page-title {
        text-align: center;
        font-size: 180%;
        line-height: 1.2;
        margin-bottom: 1em;
        margin-right: .4em;
      }

      .field {
        text-align: center;
        font-size: 140%;
        margin-bottom: .4em;

        &.field-label-inline {
          div {
            display: inline-block;
          }
          .field-label {
            float: none;
          }
          .field-items {
            float: none;
          }
        }

        &.field-name-body {
          text-align: left;
          font-size: 100%;
          margin-bottom: 0;
        }
      }
    }
  }
}

// Home
body.front {
  #main {
    height: 100vh;
  }
  .region-highlighted {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  #content {
    position: absolute;
    z-index: 2;
    width: 320px;
    height: 410px;
    left: 11vw;
    padding: 50px 0 0;
    @media screen and (max-width: $page_inner_maxwidth) {
      left: $page_horizontal_padding;
    }
    @media screen and (max-width: $mobile_menu_min_width) {
      top: 0;
      left: 0;
      width: 100%;
      padding: 0 0 5px;
      height: auto;
      background-color: white;
    }
  }

  // Home sidebar
  .region-sidebar-first {
    @media screen and (max-width: $mobile_menu_min_width) {
      display: none;
    }
    &.collapsable {
      position: relative;
      overflow: hidden;
      button[data-action="open-sidebar"] {
        position: absolute;
        top: -6px;
        left: 10px;
        z-index: 2;
        outline: none;
        padding: 0;
        width: 50px;
        height: 50px;
        background: none;
        border: none;
        &:before {
          @include iconize(-50px, -50px, 2, 2);
          content: " ";
        }
      }
      &.closed {
        button[data-action="open-sidebar"] {
          &:before {
            background-position: 0 -50px;
          }
        }
        > .inner {
          opacity: 0;
          top: -400px;
        }
      }
      > .inner {
        position: relative;
        z-index: 1;
        top: 0;
        transition: all .5s ease;
        background-color: white;
        padding-top: 30px;
      }
    }
  }
}
// Home Slider
.view-slider-home {
  .item-list {
    .slick-slider {
      overflow: hidden;
      .slick-slide {
        height: 100vh;
      }
      button.slick-arrow {
        top: 485px;
        @media screen and (max-width: $mobile_menu_min_width) {
          top: 38vw;
        }
      }
    }
  }
  .views-row {
    .node-banner {
      .field-name-field-banner-img {
        img {
          width: 100%;
        }
      }
    }
  }
}

// exhibitions list
.view-exhibitions {
  .view-content {
    column-count: 2;
    column-gap: 20px;
    @media screen and (max-width: $micro_screen_width) {
      column-count: 1;
      column-gap: 0;
    }
    .item-list {
      margin-bottom: 10px;
      h3 {
        font-size: 140%;
        line-height: 1.4;
      }
    }
  }

  .views-row {
    margin-bottom: 5px;
    .views-field-title {
      font-size: 140%;
      line-height: 1.4;
    }
    .views-field-field-city {
      font-size: 140%;
      line-height: 1.4;
    }
  }
}

// pieces in collections list
.view-pieces-in-collections {
  .views-row {
    @extend %clearfix;
    margin-bottom: 8px;
    .views-field-title {
      font-size: 140%;
      line-height: 1.4;
      font-weight: 600;
    }
    .views-field-field-city {
      float: left;
      font-size: 140%;
      line-height: 1.4;
      margin-right: 5px;
    }
    .views-field-field-country {
      float: left;
      font-size: 140%;
      line-height: 1.4;
    }
  }
}

// news list
.view-news {
  &.views-press {
    .views-row {
      margin-bottom: 20px;
      height: auto;
      padding-left: 0;
      padding-bottom: 15px;
      &:after {
        left: 0;
      }
    }
  }
  .views-row {
    @extend %clearfix;
    margin-bottom: 40px;
    height: 160px;
    overflow: hidden;
    padding-left: 277px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 277px;
      width: 75px;
      height: 1px;
      background-color: $theme_grey_light;
    }
    .views-field-field-images {
      position: absolute;
      top: 0;
      left: 0;
      a {
        display: block;
      }
    }
    .views-field-created {
      font-size: 140%;
      margin-bottom: .7em;
    }
    .views-field-title-field {
      font-size: 140%;
      line-height: 1.4;
      font-weight: 600;
      a {
        color: black;
      }
    }
    .views-field-body {
      p {
        margin-bottom: .2em;
      }
    }
    .views-field-nid, .views-field-field-link {
      font-size: 140%;
      a {
        &:after {
          content: '>';
          margin-left: 4px;
        }
      }
    }
    .views-field-field-file {
      font-size: 140%;
    }
  }
}

// user register form
#user-register-form {
  #edit-select-roles {
    display: none;
  }
}

/***** FOOTER *****/
#footer {

  float: right;
  padding: 20px $page_horizontal_padding 0 0;
  body.front & {
    display: none;
  }
  @media screen and (max-width: $big_screen_width) {
    float: none;
    padding-top: 40px;
  }
  #page.with-image & {
    width: 50%;
    @media screen and (max-width: $big_screen_width) {
      width: auto;
    }
  }
  > .inner {
    @extend %wrapper;
    text-align: right;
  }
}

/* footer copy */
#block-block-4 {
  display: inline;
  div {
    display: inline;
  }
  p {
    display: inline;
    font-size: 140%;
  }
}

/* legal menu */
#block-menu-menu-legal {
  display: inline;
  div {
    display: inline;
  }
  nav {
    display: inline;
    ul.menu {
      display: inline;
      margin: 0;
      padding: 0;
      li {
        display: inline;
        margin-left: 25px;
        line-height: 2;
        list-style-type: none;
        list-style-image: none;
        a {
          font-size: 140%;
          color: $theme_grey_light;
          &:hover {
            color: black;
          }
        }
        &.active-trail a, a.active {
          color: black;
        }
      }
    }
  }
}

/* cookies sliding popup */
#sliding-popup {
  .popup-content {
    #popup-text {
      padding: 20px 0;
      margin: 0;
      h2 {
        font-weight: normal;
        font-size: 16px;
      }
    }
  }
}
