/**
* External Plugins CSS
*/

/* Slick */
@import "../node_modules/slick-carousel/slick/slick.scss";
.slick-slider {
  * {
    outline: none;
  }
}
button.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 2;
  padding: 0;
  width: 50px;
  height: 100px;
  margin-top: -50px;
  background: none;
  border: none;
  &:before {
    @include iconize(-100px, -50px, 2, 4);
    content: " ";
  }
  &:hover {
    background: none;
  }
  span {
    display: none;
  }
  &.slick-disabled {
    cursor: default;
    opacity: .2;
  }
  &.slick-prev {
    left: 20px;
    &:before {
      background-position: -100px -50px;
    }
  }
  &.slick-next {
    right: 20px;
    &:before {
      background-position: -150px -50px;
    }
  }
}
.slick-dots {
  position: relative;
  top: -35px;
  text-align: center;
  li {
    display: inline-block;
    margin-left: 5px;
    button {
      @include hide-text;
      padding: 0;
      width: 16px;
      height: 16px;
      border: 2px solid white;
      border-radius: 50%;
      background: white;
    }
    &.slick-active {
      button {
        background: $theme_red;
      }
    }
  }
}

/* overlay */
@import "../node_modules/jquery-colorbox/example1/colorbox.css";
#cboxOverlay {
  background: black;
}
#cboxTopLeft, #cboxTopCenter, #cboxTopRight, #cboxMiddleLeft, #cboxMiddleRight, #cboxBottomLeft, #cboxBottomCenter, #cboxBottomRight {
  display: none;
}
#cboxContent {
  padding: 20px;
}
#cboxClose {
  overflow: hidden;
  width: 50px;
  height: 50px;
  bottom: auto;
  top: 0;
  background: none;
  &:before {
    @include iconize(-350px, -50px, 2, 2);
    content: " ";
  }
}
