/**
 * @file
 * Commerce modules
 *
 */

// user menu blocks
#block-menu-menu-unlogged, #block-system-user-menu {
  position: fixed;
  z-index: 100;
  top: 25px;
  right: 110px;
  @media screen and (max-width: $mobile_menu_min_width) {
    top: 65px;
  }
  ul.menu {
    li {
      display: inline;
      margin-left: 10px;
      a {
        font-size: 140%;
        color: $theme_grey_light;
        &:hover {
          color: $theme_red;
        }
      }
    }
  }
}

%cartquantity {
  width: 65px;
  overflow: hidden;
  text-align: right;
  font-size: 300%;
  font-weight: 600;
  color: $theme_grey_light;
  line-height: 1;
  &:before {
    content: " ";
    float: left;
    display: block;
    height: 21px;
    width: 1px;
    margin-top: 7px;
    margin-right: 5px;
    background-color: $theme_red;
  }
  &:after {
    @include iconize(0, -150px, 1, 1);
    content: " ";
    float: right;
    margin-left: 4px;
    margin-top: 5px;
  }
}

// cart table
.commerce-line-item-views-form {
  table.views-table {
    border: none;
    border-top: 1px solid $theme_red;
    tr {
      vertical-align: inherit;
    }
    th, td {
      padding: 15px;
      border: none;
      border-bottom: 1px solid $theme_grey_lighter;
      font-size: 140%;
      &.price {
        text-align: right;
      }
      &.views-field-field-images {
        width: 60px;
        padding: 12px 0;
        text-align: center;
        img {
          max-height: 60px;
        }
      }
      &.views-field-quantity {
        width: 90px;
        text-align: right;
        padding: 6px;
      }
      &.views-field-edit-delete {
        width: 24px;
        padding: 0;
        input[type="submit"] {
          @include hide-text;
          display: block;
          margin: 0 auto;
          width: 24px;
          height: 24px;
          border: none;
          background: transparent url("../img/delete_btn.png") no-repeat center center;
        }
      }
      input[type="text"] {
        margin: 0 auto;
        width: 50px;
        font-size: 100%;
      }
    }
  }
  .line-item-summary {
    margin: 0;
    .line-item-total {
      padding: 20px 15px;
      font-size: 140%;
    }
  }
}

// cart block
#block-commerce_cart-cart {
  position: fixed;
  z-index: 100;
  top: 15px;
  right: 30px;
  @media screen and (max-width: $mobile_menu_min_width) {
    top: 55px;
    right: $page_horizontal_padding;
  }
  &:hover {
    width: auto;
    height: auto;
    right: 18px;
    .popup {
      display: none;
    }
    > .inner {
      display: block;
    }
  }
  .popup {
    @extend %clearfix;
    padding: 0;

    .quantity {
      @extend %cartquantity;
    }
  }
  > .inner {
    display: none;
    width: 500px;
    padding: 14px;
    background-color: white;
    border: 1px solid $theme_grey_light;
    @media screen and (max-width: $small_screen_width) {
      width: 100%;
    }
    > header {
      h1 {
        @extend %clearfix;
        font-size: 160%;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .cart-empty-block {
      font-size: 140%;
      line-height: 1.4;
    }
  }
  .view-commerce-cart-block {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;

    .line-item-summary {
      .line-item-total {
        padding: 20px 130px 20px 0;
        .line-item-total-label {
          padding-left: 75px;
          display: inline-block;
          float: left;
        }
        .line-item-total-raw {
          text-align: right;
        }
      }
      .line-item-quantity {
        position: absolute;
        top: 7px;
        right: 12px;
        .line-item-quantity-raw {
          @extend %cartquantity;
          display: block;
        }
        .line-item-quantity-label {
          display: none;
        }
      }

      .links {
        position: relative;
        top: -46px;
        height: 25px;
        li {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
          &.line-item-summary-checkout{
            a {
              background-color: $theme_red;
              border: none;
              color: white;
            }
          }
          a {
            @include buttonize;
            padding: 5px 8px;
            width: 100px;
          }
        }
      }
    }
  }
}

// add to cart button
%addtocartbutton {
  max-width: 135px;
}
form.commerce-add-to-cart {
  text-align: center;
  input[type="submit"] {
    @extend %addtocartbutton;
    &[data-action="contact-us"] {
      border-color: $theme_grey_dark;
      opacity: 1;
      color: $theme_grey_dark;
      &:hover {
        opacity: 1;
      }
    }
  }
}
a[data-action="view-all-options"] {
  @extend %addtocartbutton;
}

// cart summary and commerce line items table
.view-commerce-cart-summary, .view-commerce-line-item-table {
  td {
    &.views-field-commerce-unit-price, &.views-field-commerce-total {
      text-align: right;
      table {
        border: none;
        tr {
          display: none;
          &.component-type-base-price {
            display: table-row;
            .component-title {
              display: none;
            }
          }
        }
        td {
          padding: 0;
          border: none;
        }
      }
    }
    &.views-field-quantity {
      text-align: center;
    }
  }
  .commerce-order-handler-area-order-total {
    width: 33%;
    margin-left: auto;
    .field {
      font-size: 160%;
      border: 1px solid #a4a5a7;
      border-top: none;
      table td & {
        font-size: 100%;
      }
      .field-label {
        padding: 5px 10px;
        border-right: 1px solid #a4a5a7;
      }
      .field-items {
        padding: 5px 10px;
        float: right;
        font-weight: bold;
      }
    }
  }
}

// checkout pages forms
body.page-checkout {
  #content-inner {
    form {
      fieldset {
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid $theme_grey_lighter;

        &.account {
          .form-item {
            font-size: 170%;
            label, .description, input[type="text"], input[type="password"], input[type="email"], textarea, select {
              font-size: 100%;
            }
          }
        }

        &.checkout-buttons {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
          .button-operator {
            margin: 0 10px;
          }
        }
      }

      &#commerce-checkout-form-checkout {
        fieldset.account {
          .form-item {
            label {
              font-weight: bold;
            }
          }
        }

        fieldset.customer_profile_billing, fieldset.customer_profile_shipping {
          .field {
            margin-bottom: 10px;
            .field-label {
              font-weight: normal;
              font-size: 180%;
              line-height: 1.4;
            }
            .field-item {
              font-size: 180%;
              line-height: 1.4;
            }
          }
        }
      }

      &#commerce-checkout-form-review {
        .checkout-help {
          font-size: 160%;
          margin-bottom: 1em;
        }

        table.checkout-review {
          margin-bottom: 30px;
          > tbody {
            > tr.pane-title {
              > td {
                padding: 10px 20px;
              }
            }
            > tr.pane-data {
              > td {
                padding: 20px;
              }
            }
          }
          td {
            label {
              font-size: 100%;
              font-weight: bold;
            }
          }
        }

        fieldset.commerce_payment {
          #payment-details {
            @extend %clearfix;
          }
          .commerce-credit-card-expiration {
            float: left;
            margin-right: 20px;
            label, .form-item, select {
              display: inline;
              width: auto;
            }
            label {
              margin-right: 10px;
            }
          }

          .form-item-commerce-payment-payment-details-credit-card-code {
            float: left;
            label, input[type="text"] {
              display: inline;
              width: auto;
            }
            label {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.view-commerce-cart-form {
  .commerce-line-item-actions {
    input[type="submit"] {
      margin-left: 10px;
      &#edit-checkout {
        border-color: $theme_red;
        background-color: $theme_red;
        color: white;
      }
    }
  }
}

// entrar, mayoristas and pre checkout page
body.section-entrar, body.section-mayoristas, body.section-pre-checkout {
  #content-inner {
    @extend %clearfix;
    padding-top: 60px;

    .block {
      float: left;
      width: 50%;
      padding: 30px;
      > .inner {
        > header {
          h1 {
            font-size: 240%;
            margin-bottom: 1em;
          }
        }

        > .content {
          a[data-action], input[type="submit"], a.btn {
            width: 100%;
          }
          a.btn {
            @include buttonize;
            font-size: 100%;
          }
        }
      }

      &.block-user {
        border-right: 1px solid $theme_grey_lighter;
      }
    }
  }
}

// product categories
.view-shop {
  .view-content {
    > .item-list {
      > ul {
        @include simple-column-container;
      }
    }
  }
  .views-row {
    @include simple-column(3, 6%);
    margin-bottom: 45px;
    @media screen and (max-width: $bigger_screen_width) {
      @include simple-column(2, 6%);
    }
    @media screen and (max-width: $big_screen_width) {
      @include simple-column(3, 6%);
    }
    @media screen and (max-width: $small_screen_width) {
      @include simple-column(2, 6%);
    }
    @media screen and (max-width: $micro_screen_width) {
      @include simple-column(1, 0);
    }
    .views-field-field-image {
      margin-bottom: 20px;
      a {
        display: block;
        img {
          width: 100%;
        }
      }
    }
    .views-field-name-field {
      font-size: 180%;
      text-align: center;
      a {
        color: black;
      }
    }
  }
}

// shop go back button
%shopgobackbutton {
  background: none;
  border: none;
  padding: 0;
  font-size: 160%;
  margin-bottom: 20px;
  &:before {
    content: "<";
    margin-right: 4px;
  }
  &:hover {
    background: none;
    color: $theme_red;
  }
}

// product category products list
.view-products {
  [data-action="back-to-categories"] {
    @extend %shopgobackbutton;
  }

  > .body {
    margin-bottom: 30px;
  }

  .view-content {
    > .item-list {
      > ul {
        @include simple-column-container;
      }
    }
  }

  .views-row {
    @include simple-column(3, 3%);
    text-align: center;
    margin-bottom: 35px;
    @media screen and (max-width: $small_screen_width) {
      @include simple-column(2, 3%);
    }
    @media screen and (max-width: $micro_screen_width) {
      @include simple-column(1, 0);
    }
    .views-field-field-images {
      margin-bottom: 10px;
      a {
        display: block;
      }
    }

    .views-field-title {
      font-size: 160%;
      margin-bottom: 5px;
      a {
        color: black;
      }
    }

    .views-field-field-piece-height, .views-field-field-diameter {
      font-size: 140%;
      margin-bottom: 10px;
    }

    .empty-line {
      display: block;
      height: 28px;
    }

    .views-field-commerce-price {
      font-size: 150%;
      font-weight: 600;
      color: $theme_red;
      margin-bottom: 10px;
    }
  }
}

// Product display detail
article.node-full.node-product-display {
  padding-bottom: 50px;
  [data-action="back-to-list"] {
    @extend %shopgobackbutton;
  }

  .product-edit-links {
    margin-bottom: 20px;
    h2 {
      display: inline;
    }
    ul {
      display: inline;
      li {
        display: inline;
        line-height: 2;
        a {
          font-size: 120%;
          padding: .1em .3em;
          border: 1px solid $theme_red;
          &:hover {
            opacity: .6;
          }
        }
      }
    }
  }

  .commerce-product-field, .commerce-product-extra-field {
    text-align: center;
  }

  .field-field-images {
    margin-bottom: 20px;
    .field-items {
      .field-item {
        margin-bottom: 10px;
      }
    }
  }

  .field-description-field {
    .field-type-text-with-summary {
      margin: 0 auto;
    }
  }

  .field-title-field, .commerce-product-title {
    font-size: 180%;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: .4em;

    .commerce-product-title-label {
      display: none;
    }
  }

  .field-name-field-piece-height, .field-name-field-diameter {
    font-size: 160%;
    margin-bottom: .5em;
  }

  .field-commerce-price {
    font-size: 160%;
    font-weight: 600;
    color: $theme_red;
    margin-bottom: 1.5em;
  }

  .attribute-widgets {
    margin: 10px 0 0;
    > .form-item {
      text-align: center;
      margin-bottom: 20px;
      > label {
        font-weight: bold;
        margin-right: 20px;
        &:after {
          content: ':';
        }
      }
      div, label, select {
        display: inline-block;
      }
      select {
        width: auto;
      }
    }
  }
}

// commerce related user profile pages
body.page-user-addressbook {
  #content-inner {
    @extend %clearfix;
    .action-links {
      a {
        @include buttonize;
        margin-bottom: 20px;
      }
    }
  }
}
.addressbook-nodata {
  font-size: 160%;
  line-height: 1.25;
}
.view-commerce-addressbook-defaults, .view-commerce-addressbook {
  float: left;
  .view-content {
    @extend %clearfix;
  }
  .views-row {
    position: relative;
    width: 200px;
    height: 300px;
    float: left;
    margin: 0 20px 20px 0;
    padding: 10px;
    border: 1px solid $theme_grey_light;
    .field {
      font-size: 160%;
      line-height: 1.25;
      .field-item {
        div, .country {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
    .addressbook-links {
      position: absolute;
      bottom: 5px;
      left: 5px;
      font-size: 160%;
      line-height: 1.2;
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.view-commerce-addressbook {
  .views-row {
    color: $theme_grey_light;
    border-color: $theme_grey_lighter;
    background-color: $theme_grey_lightest;
  }
}

// user orders
body.page-user-orders {
  #content-inner {
    .entity-commerce-order {
      .field-type-commerce-price {
        margin-bottom: 20px;
        table {
          border-top: none;
          tr {
            display: none;
            &.component-type-commerce-price-formatted-amount {
              display: table-row;
              background: none;
            }
          }
        }
      }
      .field-type-commerce-customer-profile-reference {
        margin-bottom: 20px;
        .field-label {
          font-size: 170%;
          margin-bottom: .4em;
        }
        .field-item {
          font-size: 170%;
          line-height: $base-line-height;
          .field-label, .field-item {
            font-weight: normal;
            font-size: 100%;
          }
        }
      }
    }
  }
}