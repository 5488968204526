/**
 * @file
 * Pieces modules
 *
 */

// pieces list
.view-pieces {
  > header {
    h1 {
      font-size: 180%;
      margin-bottom: 20px;
    }
  }

  .item-list {
    > ul {
      @include simple-column-container;
    }
    .views-row {
      @include simple-column(3, 8%);
      position: relative;
      height: 24vw;
      max-height: 320px;
      overflow: hidden;
      @media screen and (max-width: $big_screen_width) {
        height: 35vw;
        max-height: none;
      }
      @media screen and (max-width: $small_screen_width) {
        @include simple-column(2, 8%);
        height: 54vw;
        max-height: none;
      }
      @media screen and (max-width: $micro_screen_width) {
        @include simple-column(1, 0);
        height: auto;
      }
      .views-field-field-image {
        margin-bottom: 7px;
        div, a, img {
          display: block;
          width: 100%;
        }
      }

      .views-field-title {
        font-size: 140%;
        font-weight: 600;
        height: 1.3em;
        overflow: hidden;
        a {
          color: black;
        }
      }

      .views-field-field-title-en {
        font-size: 140%;
        font-weight: 600;
        font-style: italic;
        height: 1.3em;
        overflow: hidden;
        a {
          color: $theme_grey_darker;
        }
      }

      .views-field-field-year {
        font-size: 140%;
        margin-bottom: 20px;
      }

      .views-field-edit-node {
        position: absolute;
        bottom: 10px;
        right: 0;
      }
    }
  }
}

// pieces page exposed filters
button[data-action="open-filters"] {
  display: none;
  margin-left: $page_horizontal_padding;
  @media screen and (max-width: $mobile_menu_min_width) {
    display: block;
  }
}
#block-views--exp-pieces-page {
  border-top: 1px solid $theme_red;
  margin-top: 15px;
  padding-top: 15px;
  @media screen and (max-width: $mobile_menu_min_width) {
    height: 0;
    overflow: hidden;
    opacity: 0;
    margin-top: 10px;
    padding-top: 0;
    border-top: none;
    border-bottom: 1px solid black;
    transition: opacity ease .5s;

    &.open {
      height: auto;
      opacity: 1;
    }
  }
  > .inner {
    > header {
      h1 {
        color: $theme_red;
      }
    }
  }

  .views-exposed-form {
    .views-exposed-widgets {
      select {
        max-width: none;
      }

      .views-widget-filter-field_piece_available_value {
        @extend %clearfix;
        label {
          float: left;
          padding-top: 5px;
          margin-right: 10px;
        }
        .views-widget {
          float: left;
        }
      }
    }
  }
}
