// @file
// Custom sass mixins

// a clearfix extension
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

%wrapper {
  margin: 0 auto;
  max-width: $page_inner_maxwidth;
  @media screen and (max-width: ($page_inner_maxwidth + 2*$page_horizontal_padding)) {
    padding: 0 $page_horizontal_padding;
  }
}

%narrowbody {
  max-width: $body_width;
}

// go back link
%go-back-link {
  background: none;
  color: $theme_grey_light;
  border: none;
  text-transform: none;
  margin-bottom: 20px;
  &:before {
    content: "<";
    margin-right: 5px;
  }
}

// pager item
%pager__item {
  display: inline;
  font-size: 140%;
  padding: 0 8px;
  text-align: center;
  list-style-type: none;
  background-image: none;
  a {
    color: black;
  }
}

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

/* The Simple Grid System */
@mixin simple-column-container {
  @extend %clearfix;
}
@mixin simple-column($column-count, $gutter-width) {
  float: left;
  width: ((100% - (($column-count - 1) * $gutter-width)) / $column-count);
  &:nth-child(n) {
    margin-right: $gutter-width;
  }
  &:nth-child(#{$column-count}n) {
    margin-right: 0;
  }
}

// hide the text
@mixin hide-text() {
  text-indent: -500em;
}

// one column-ize
@mixin one-columnize() {
  float: none;
  position: static;
  width: 100%;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  clear: both;
  margin: 0 auto 20px;
}

// create inline list and separate by commas
@mixin separate-by-commas(){
  display: inline;
  &:after {
    content: ",";
    padding: 0 .2em 0 0;
  }
  &:last-child:after {
    content: "";
    padding: 0;
  }
}

// buttons
@mixin buttonize {
  display: inline-block;
  font-size: 140%;
  color: $theme_grey_light;
  text-align: center;
  font-weight: 600;
  padding: 5px 10px;
  background: white;
  border: 1px solid $theme_grey_light;
  border-radius: 0;
  transition: all .3s ease;
  &:hover, &:active {
    color: white;
    border-color: $theme_red;
    background-color: $theme_red;
  }
  &[disabled] {
    &:hover, &:active {
      color: inherit;
      border-color: inherit;
      background-color: inherit;
    }
  }
}

// icons
@mixin iconize ($left: 0, $top: 0, $block_width: 1, $block_height: 1) {
  display: block;
  width: (25px * $block_width);
  height: (25px * $block_height);
  background: url("../img/sprite.png") no-repeat $left $top;
}

// mobile header
@mixin mobileheader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  margin-bottom: 0;
  background-color: white;
}
